<template>
  <section class="pt-xl bg-dark">
    <div class="live-seminars-bg"></div>

    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-5 offset-lg-7">
          <img
            src="@/assets/img/live_seminars_logo.svg"
            class="mb-sm"
            alt="Live Seminars"
          />

          <p class="text-white">
            Bujin.tv is pleased to announce support for "Live Seminars" coming
            soon! We are integrating Zoom applications into www.bujin.tv to
            offer online martial arts teachers an easy to use, easy to access
            service to post invitations to seminar events, register participants
            and host the seminar video online on www.bujin.tv. Please follow our
            Facebook and Instagram for updates on our upcoming Live Seminars
            service.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "live-seminars",
};
</script>

<style lang="scss" scoped>
/* section > .bg {
  width: 70%;
  height: 100%;
  background-image: url("~@/assets/img/live_seminars_bg.png");
  background-size: cover;
  background-position: top left;
  background-repeat: no-repeat;
} */

.live-seminars-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;

  background-image: url("~@/assets/img/live_seminars_bg.png");
  background-size: auto 100%;
  background-position: 80%;
  background-repeat: no-repeat;

  box-shadow: inset 0 0 0 100vh rgba(61, 60, 60, 0.8);

  @media (min-width: 768px) {
    width: 60%;
    background-position: right;
    box-shadow: none;
  }
}
</style>
